import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmDialog = ({ onConfirm, title, message, trigger, disabled = false }) => {
  const [showModal, setShowModal] = useState(false);

  const handleTriggerClick = (e) => {
    if (!disabled) {
      setShowModal(true);
    }
  };

  const onClose = (e) => {
    setShowModal(false);
  };

  const onConfirmClick = (e) => {
    onConfirm(e);
    setShowModal(false);
  };

  return (
    <>
      {React.isValidElement(trigger) && React.cloneElement(trigger, { onClick: handleTriggerClick,disabled })}
      <Modal size="xl" show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => onConfirmClick(e)} variant="danger" className="col-sm-2">Yes</Button>
          <Button onClick={(e) => onClose(e)} variant="primary" className="col-sm-2">No</Button>
          <Button variant="secondary" onClick={(e) => onClose(e)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>);
}

export default ConfirmDialog;