import React, { useContext, useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, IconButton, Menu, MenuItem, Box } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useAuth from "./hooks/useAuth";
import { CompanyContext } from './context/CompanyContext';

// Define the last update date for the welcome page
const WELCOME_PAGE_UPDATE_DATE = '2024-11-19';

const Layout = () => {
    const navigate = useNavigate();
    const { auth, revertToOriginalUser, originalUser } = useAuth();
    const { company } = useContext(CompanyContext);
    const isAuthenticated = !(auth?.username == null);
    const isAdmin = auth?.roles?.includes("admin");
    const isReseller = auth?.roles?.includes("reseller");

    const [cookies, setCookie] = useCookies(['welcome_page_seen']);
    const [showWelcomePage, setShowWelcomePage] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const cookieDate = cookies.welcome_page_seen;
        if (!cookieDate || new Date(cookieDate) < new Date(WELCOME_PAGE_UPDATE_DATE)) {
            setShowWelcomePage(true);
        }
    }, [cookies, setCookie]);

    return (
        <Box>
            <AppBar position="static" sx={{ backgroundColor: '#e0e0e0', boxShadow: 'none', padding: '8px 0' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Logo aligned to the left */}
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                        <Button component={Link} to="/" sx={{ padding: 0, minWidth: 'auto' }}>
                            <img src="/assets/images/tara-logo.png" alt="Logo" style={{ height: 40 }} />
                        </Button>
                    </Box>

                    {isAuthenticated && (
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button sx={{ color: 'black' }} component={Link} to="/">Home</Button>
                            {company && <Button sx={{ color: 'black' }} component={Link} to="/dashboard">Dashboard</Button>}
                            <Button sx={{ color: 'black' }} component={Link} to="/welcome">
                                Welcome {showWelcomePage && <StarIcon sx={{ fontSize: 16, ml: 0.5, color: 'black' }} />}
                            </Button>
                            <Button sx={{ color: 'black' }} component={Link} to="/eula">EULA</Button>

                            {(isAdmin || isReseller) && (
                                <>
                                    <Button sx={{ color: 'black' }} component={Link} to="/svintel">SV Intel</Button>
                                    <Button
                                        sx={{ color: 'black' }}
                                        endIcon={<ArrowDropDownIcon sx={{ color: 'black' }} />}
                                        onClick={handleMenuOpen}
                                    >
                                        Admin
                                    </Button>
                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                        <MenuItem onClick={() => navigate('/admin/users', { replace: true })}>Users</MenuItem>
                                        <MenuItem onClick={() => navigate('/admin/companies', { replace: true })}>Companies</MenuItem>
                                        {isAdmin && <MenuItem onClick={() => navigate('/admin/resellers', { replace: true })}>Resellers</MenuItem>}
                                    </Menu>
                                </>
                            )}

                            <Button sx={{ color: 'black' }} component={Link} to="/logout">Logout</Button>
                        </Box>
                    )}
                    <Typography variant="h6" sx={{ flexGrow: 1,fontSize: "0.875rem", color: "gray" }}>
                        {auth?.username || "Guest"}
                    </Typography>
                    {/* Show the "Revert Back" button only if switched user */}
                    {originalUser && (
                        <Button color="secondary" variant="contained"  sx={{ fontSize: "0.75rem", padding: "4px 8px" }} onClick={revertToOriginalUser}>
                            Revert to {originalUser.username}
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <Box sx={{ padding: 2 }}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default Layout;