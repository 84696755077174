import { createContext, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { notice_error, notice_success } from '../utils/toast_helpers';
import { useCompany } from "./CompanyContext";
import restHandler from '../apiClient.js';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [originalUser, setOriginalUser] = useState(null);
    const navigate = useNavigate();
    const { setCompany } = useCompany();
    const [reloadKey, setReloadKey] = useState(0);    

    const updateAuth = (newAuth) => {
        setAuth(newAuth);
        setReloadKey((prev) => prev + 1);
    }

    // Function to switch user (Become Another User)
    const becomeUser = (newUser) => {
        if (!originalUser) {
            setOriginalUser(auth); // Store original user only if it's not already set
        }
        updateAuth(newUser);
    };

    // Function to revert back to original user
    const revertToOriginalUser = async () => {
        if (originalUser) {
            try{
            const data = await restHandler('POST', `/api/revert/${originalUser.uuid}`);
            updateAuth(data);
            setOriginalUser(null); // Clear the stored original user
            notice_success(`Reverted ${data.username}`);
            setCompany(null);
            navigate('/', { replace: true });
            } catch (err) {
                notice_error(err.message || err.detail || 'Error fetching data');
            }
        }
    };

    return (
        <AuthContext.Provider value={{ auth, setAuth, becomeUser, revertToOriginalUser, originalUser,updateAuth, reloadKey }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
export const useAuth = () => useContext(AuthContext);