import React from "react";
import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box 
      component="footer"
      sx={{ 
        backgroundColor: "white",//"#e0e0e0", 
        color: "black", 
        py: 2, 
        mt: "auto", 
        textAlign: "center",
        padding: '8px 0'
      }}
    >
      <Typography variant="body2">
        &copy; 2024 Security Vitals. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;