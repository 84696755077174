import React, { useState } from 'react';
import { Button, Modal, Box, TextField, Typography, Checkbox, FormGroup, FormControlLabel, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Layout from './Layout';
import restHandler from './apiClient';
import VulnerabilityModal from './VulnerabilityModal';
import Loading from './components/Loading';

function SvIntel() {
    const [query, setQuery] = useState('');
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const columnDefs = [
        { headerName: "CVE", field: "_id", flex: 1 },
        { headerName: "Description", field: "description", cellClass: "ag-left-align", flex: 3 },
    ];

    const openModal = (event, rowData) => {
        event.preventDefault(); // Prevent form submission
        setSelectedRowData(rowData._id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedRowData(null);
    };

    const onRowClicked = (event) => {
        const rowData = event.data;
        setSelectedRowData(rowData._id);
        setIsModalOpen(true);
    }

    // Handle search input change
    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    // Fetch data from API
    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await restHandler('GET', `/api/query_svintel?query=${query}`);
            setRowData(result.data || []);
        } catch (error) {
            throw new Error(error.detail || error);
        }
        setLoading(false);
    };

    // Handle search button click
    const handleSearch = (e) => {
        e.preventDefault();
        fetchData();
    };

    return (
        <>
            <Layout />
            {selectedRowData && (<VulnerabilityModal isOpen={isModalOpen} onClose={closeModal} cve={selectedRowData} />)}
            <Box display="flex" flexDirection="column" gap={2}>
                <h3> SV Intelligence</h3>
                <Box sx={{ width: '100%' }}>
                    <form onSubmit={handleSearch}>
                        <TextField
                            value={query}
                            onChange={handleInputChange}
                            placeholder="Enter search query"
                            sx={{ padding: "8px", width: "70%", marginRight: "10px" }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ padding: "8px 12px" }}
                        >{loading ? "Loading..." : "Search"}
                </Button>
                    </form>
                </Box>
                {loading ? <Loading /> : <div className="ag-theme-quartz-auto-dark" style={{ height: 400, width: "100%", marginTop: "20px" }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        onRowClicked={onRowClicked}
                    />
                </div>
                }       </Box>
        </>
    );
}

export default SvIntel;