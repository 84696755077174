import React from 'react';
import { Link } from 'react-router-dom';
import './Unauthorized.css'; // Optional CSS for styling

const Unauthorized = () => {
  return (
    <div className="unauthorized-page">
      <h1>403 - Unauthorized</h1>
      <p>You do not have permission to view this page.</p>
      <Link to="/">Go back to the homepage</Link>
    </div>
  );
};

export default Unauthorized;